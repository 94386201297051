.App {
  /*  text-align: center; */
  /*background-color: #282c34;*/
  align-items: flex-start;
}

.grid-layout {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  font-family: "Helvetica";
  margin-left: 10px;
}

.App-logo {
  pointer-events: none;
  margin-right: 40px;
  justify-content: right;
  align-content: right;
  text-align: right;
  vertical-align: middle;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-above-header {
  /*background-color: #282c34;*/
}

.header-contact {
  text-align: left;
  font-size: 24px;
  margin-left: 20px;
}

.App-header {
  background-color: #000000;
  font-size: 1px;
  color: white;
  border-radius: 15px;
  margin-left: 20px;
  margin-right: 20px;
  opacity: 0.8;
  display: block;
}

.App-table-header {
  width: 100%
}

.App-body {
  /*background-color: #282c34;*/
  min-height: 100vh;
/*  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
*/  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: yellow;
  opacity: 0.4;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.title-header {
  background-color: #080808;
  font-size: 20px;
  color: #ffffbb;
}

.subtitle-header {
  background-color: #080808;
  font-size: 20px;
  color: #ffffff;
}

.panel-header {
  background-color: #080808;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  font-size: 20px;
  color: #ffffbb;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 12px;
}

.panel-body {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  font-size: 16px;
  color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
}

.panel-table {
  background-color: #080808;
  padding: 0px;
  margin: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.panel-thumb {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 6px;
  margin-top: 6px;
}

.linkout {
  text-decoration: none;
  color: yellow;
}